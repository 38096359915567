import type { LocationQuery } from '#vue-router';

const ifStringHasSubstring = (mainString: string, subString: string) => {
    if (!mainString || !subString) {
        return false;
    }
    const lowerMainString = mainString.toLowerCase();
    const lowerSubString = subString.toLowerCase();
    return lowerMainString.includes(lowerSubString);
};

// can be used to return a variety of title compositions,
// but always with the specific irregularities removed
export function getCleanVehicleTitleArray({
    year = '',
    make,
    model,
    trim = '',
}: {
    year?: string;
    make?: string | null;
    model?: string | null;
    trim?: string | null;
}) {
    const titleArray = [];

    if (year) {
        titleArray.push(year);
    }

    // conditions for including make
    if (make) {
        if (
            !model ||
            // this prevents Mazda Mazda3, etc
            !ifStringHasSubstring(model, make)
        ) {
            titleArray.push(make);
        }
    }

    // conditions for including model
    if (model) {
        if (
            !trim ||
            // this prevents X4 X4 M40i, GLE GLE 350, etc
            !ifStringHasSubstring(trim, model)
        ) {
            titleArray.push(model);
        }
    }

    if (trim) {
        titleArray.push(trim);
    }

    return titleArray;
}

export function buildVehicleFormTitle(
    formName: string,
    queries: LocationQuery
) {
    const queryYear = queries.year ? queries.year.toString() : '';
    const queryMake = queries.make ? queries.make.toString() : '';
    const queryModel = queries.model ? queries.model.toString() : '';

    if (!queryMake) return formName;

    const cleanVehicleTitle = getCleanVehicleTitleArray({
        year: queryYear,
        make: queryMake,
        model: queryModel,
    }).join(' ');

    return `${cleanVehicleTitle} ${formName}`;
}

export const urlDecode = (input: string) => {
    if (typeof input !== 'string') {
        return input;
    }
    // (?=.) only replaces + if not at the end
    return input.replace(/[+](?=.)/g, ' ');
};

export const urlEncode = (input: string) => {
    if (typeof input !== 'string') {
        return input;
    }
    return input.replace(/\s/g, '+');
};

export const capitalize = (input: string) => {
    return input.charAt(0).toUpperCase() + input.toLowerCase().slice(1);
};

export const startCase = (input: string) => {
    return input.split(' ').map(capitalize).join(' ');
};

export const getLeadArkona = (
    carArkona: InventoryDetailVehicle['arkona'],
    carType: InventoryDetailVehicle['type'],
    arkonas: Variables['arkonas']
) => {
    // Handle case where car arkona is empty string
    if (!carArkona) {
        return arkonas.site;
    }

    if (carType.toLowerCase().startsWith('n')) {
        switch (arkonas.new) {
            case '':
            case 'store':
                return arkonas.sales || arkonas.site;
            case 'vehicle':
                return carArkona;
            default:
                assertUnreachable(arkonas.new);
        }
    } else {
        switch (arkonas.used) {
            case '':
            case 'store':
                return arkonas.sales || arkonas.site;
            case 'vehicle':
                return carArkona;
            default:
                assertUnreachable(arkonas.used);
        }
    }
};

/** Returns a string with the following pattern: ###-###-####. */
export const getHyphenatedPhoneNumber = (value: string | number) => {
    const phoneNumber = value.toString();

    const matches = phoneNumber
        .replace(/\D/g, '')
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

    if (!matches) return '';

    if (matches[3]) {
        return `${matches[1]}-${matches[2]}-${matches[3]}`;
    } else if (matches[2]) {
        return `${matches[1]}-${matches[2]}`;
    } else if (matches[1]) {
        return `${matches[1]}`;
    } else {
        return '';
    }
};
