// Type safe checking if key is part of an object
export function isKeyOfObject<T extends object>(
    key: string | number | symbol,
    obj: T
): key is keyof T {
    return key in obj;
}

export const isFrontendType = (input: string): input is FrontendCarType => {
    return input === 'new' || input === 'used' || input === 'cpo';
};

export const assertUnreachable = (_: never): never => {
    throw new Error('Uncaught switch case');
};
